import {Component, OnInit} from '@angular/core';
import {LoginInfoComponent} from "../../user/login-info/login-info.component";
import { UserService } from '../../user';
import { Router } from '@angular/router';


@Component({

  selector: 'sa-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  activeIconAccueil:string="assets/img/decoupes/accueil-active.png";
  disableIconAccueil:string="assets/img/decoupes/accueil-disable.png";

  activeIconAgence:string="assets/img/decoupes/agence-active.png";
  disableIconAgence:string="assets/img/decoupes/agence-disable.png";

  activeIconAdministration:string="assets/img/decoupes/administration-active.png";
  disableIconAdministration:string="assets/img/decoupes/administration-disable.png";

  activeIconInfos:string="assets/img/decoupes/infos-active.png";
  disableIconInfos:string="assets/img/decoupes/infos-disable.png";

  activeIconParametre:string="assets/img/decoupes/parametre-active.png";
  disableIconParametre:string="assets/img/decoupes/parametre-disable.png";
  constructor(public activeRoute:Router) {
  }

  ngOnInit() {
  }

}

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {HeaderModule} from "./header/header.module";
import {FooterComponent} from "./footer/footer.component";
import {NavigationModule} from "./navigation/navigation.module";
import {RibbonComponent} from "./ribbon/ribbon.component";
import {ShortcutComponent} from "./shortcut/shortcut.component";
import {ToggleActiveDirective} from "../utils/toggle-active.directive";
import {LayoutSwitcherComponent} from "./layout-switcher.component";
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { EmptyLayoutComponent } from './app-layouts/empty-layout.component';
import {RouterModule} from "@angular/router";
import { AuthLayoutComponent } from './app-layouts/auth-layout.component';
import {TooltipModule, BsDropdownModule,BsDatepickerModule} from "ngx-bootstrap";
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';
import {UtilsModule} from "../utils/utils.module";
import { CreateTypeUserComponent } from "app/+type-user/+create-type-user/create-type-user.component";
import { CreateTypeTransactionComponent } from "app/+type-transaction/+create-type-transaction/create-type-transaction.component";
import { CreateStatutComponent } from "../../+statut/+create-statut/create-statut.component";
import { CreateAdminComponent } from "../../+admin/+create-admin/create-admin.component";
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMaskModule } from "ngx-mask";
//import {  } from 'ngx-bootstrap';
//import {AccordionModule} from 'ngx-bootstrap/accordion'
import { ChartModule } from "angular-highcharts";
import { StatCardModule } from "../stat-card/stat-card.module";
import { CreateUpdateAgenceComponent } from "app/+agence/+create-update-agence/create-update-agence.component";
import { CreateUpdateUserComponent } from "app/+admin/+create-update-user/create-update-user.component";
import { AccountActivationComponent } from "app/+accueil/+account-activation/account-activation.component";
//import { CreateUserComponent } from "app/+admin/+create-user/create-user.component";
//import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
 import {CdkStepperModule} from '@angular/cdk/stepper';
 import {NgStepperModule} from 'angular-ng-stepper';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { ImageCropperModule } from 'ngx-image-cropper';
import { AutoTabDirective } from "../directives/auto-tab.directive";
@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    UtilsModule,
    //BrowserModule,
    //BrowserAnimationsModule,

    TooltipModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    // AccordionModule.forRoot(),
    ChartModule,
    CdkStepperModule,
    NgStepperModule,
    ImageCropperModule
  ],
  declarations: [
    AutoTabDirective,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    RouteBreadcrumbsComponent,
    CreateTypeUserComponent,
    CreateTypeTransactionComponent,
    CreateStatutComponent,
    CreateAdminComponent,
    CreateUpdateAgenceComponent,
    //CreateUserComponent
    CreateUpdateUserComponent,
    AccountActivationComponent
  ],
  exports:[
    AutoTabDirective,
    HeaderModule,
    BsDropdownModule,
    NavigationModule,
    ImageCropperModule,
    FooterComponent,
    RibbonComponent,
    ShortcutComponent,
    LayoutSwitcherComponent,
    CreateTypeUserComponent,
    CreateTypeTransactionComponent,
    CreateStatutComponent,
    CreateAdminComponent,
    CreateUpdateAgenceComponent,
    //CreateUserComponent
    CreateUpdateUserComponent,
    AccountActivationComponent
  ],
  entryComponents:[CreateTypeUserComponent,CreateTypeTransactionComponent,CreateStatutComponent,CreateAdminComponent,CreateUpdateAgenceComponent,/*CreateUserComponent*/CreateUpdateUserComponent,AccountActivationComponent]
})
export class SmartadminLayoutModule{

}

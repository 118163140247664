import {Injectable} from '@angular/core';


import {JsonApiService} from "../../core/api/json-api.service";
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import * as _ from "lodash";

@Injectable()
export class UserService {

  public user: Subject<any>;

  public userInfo = {
    username: 'Guest'
  };

  constructor(private jsonApiService:JsonApiService) {
    this.user = new Subject();
  }

  getLoginInfo():Observable<any> {
    return this.jsonApiService.fetch('/user/login-info.json')
      .do((user)=>{
        this.userInfo = user;
      this.user.next(user)
    })
  }

  isLoggedIn():boolean {
    return !!localStorage.getItem('yz-backoffice-app-collecte');
  }

  getCurrentUser()
  {
    return JSON.parse(localStorage.getItem("yz-backoffice-app-collecte"));
  }

  getPermission(code)
  {
    var returnValue = null;
    var ListeFonctionnalites = this.getCurrentUser() ? (this.getCurrentUser().fonctionnalites) : [];
    
    if(ListeFonctionnalites && ListeFonctionnalites.length > 0)
    {
      _.forEach(ListeFonctionnalites, function(valueChildSecond, keyChild) { 
        if(valueChildSecond == code)
        {
             returnValue = true;
        }
      });
    }

    return returnValue;
     
  }


}

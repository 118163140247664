import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import swal from 'sweetalert2';
import { HttpService } from 'app/core/http/http.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
@Component({
  selector: 'app-create-update-user',
  templateUrl: './create-update-user.component.html',
  styleUrls: ['./create-update-user.component.css']
})
export class CreateUpdateUserComponent implements OnInit {

  // userName:string="";
  // listeUser:any[]=[{
  //   nom:"YAO",
  //   prenom:"Kouassi",
  //   login:"yakou",
  //   contact:"09212109",
  //   statut:'COMMERCANT',
  //   createdAt:"10/10/2010"
  // }];
  // p:number=1
  @Input() roleUserToUpdate;
  onClose: Subject<boolean> = new Subject<boolean>();
  submitted = false;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  createUpdateFormGroup:FormGroup;
  constructor(public httpService:HttpService,private notification:NotificationService,public modalRef:BsModalRef,private formBuilder:FormBuilder) { 

  }

  ngOnInit() {
    this.onClose.next(false);
    this.createUpdateFormGroup = this.formBuilder.group({
      EnseigneUserID: this.roleUserToUpdate ? this.roleUserToUpdate["ID"] : '',
      Login: new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Login : '', Validators.required),
      Nom: new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Nom : '', Validators.required),
      Prenoms:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Prenoms : '', Validators.required),
      Description:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Description : '', Validators.required),
      Password: this.roleUserToUpdate ? (this.roleUserToUpdate["ID"] ? '' : new FormControl('', Validators.required)) : new FormControl('', Validators.required),
      Contact:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Contact : '', Validators.required),
      Email:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Email : '', [Validators.required,Validators.pattern(this.emailPattern)]),
      Adresse:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Adresse : '', Validators.required),
      ConfirmPassword: this.roleUserToUpdate ? (this.roleUserToUpdate["ID"] ? '' : new FormControl('', Validators.required)) : new FormControl('', Validators.required),
      //EnseigneID:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.EnseigneID : '', Validators.required)
    },{ validator: this.checkPasswords });
}
checkPasswords(group: FormGroup) { // here we have the 'passwords' group
let pass = group.controls.Password.value;
let confirmPass = group.controls.ConfirmPassword.value;
return pass === confirmPass ? null : { notSame: true }
}
get EnseigneUserID () {
  return this.createUpdateFormGroup.get('EnseigneUserID');
}
get Login() {
  return this.createUpdateFormGroup.get('Login');
}
get Nom() {
  return this.createUpdateFormGroup.get('Nom');
}
get Prenoms() {
  return this.createUpdateFormGroup.get('Prenoms');
}
get Description() {
  return this.createUpdateFormGroup.get('Description');
}
get Password() {
  return this.createUpdateFormGroup.get('Password');
}
get Contact() {
  return this.createUpdateFormGroup.get('Contact');
}
get Email() {
  return this.createUpdateFormGroup.get('Email');
}
get Adresse() {
  return this.createUpdateFormGroup.get('Adresse');
}
get ConfirmPassword() {
  return this.createUpdateFormGroup.get('ConfirmPassword');
}
// get EnseigneID() {
//   return this.createUpdateFormGroup.get('EnseigneID');
// }
createOrUpdateUser(){
  this.submitted = true;
  delete this.createUpdateFormGroup.value.ConfirmPassword;
  if(this.roleUserToUpdate && this.roleUserToUpdate["ID"]) delete this.createUpdateFormGroup.value.Password;
  else delete this.createUpdateFormGroup.value['EnseigneUserID'];
  if(this.createUpdateFormGroup.valid){
    this.httpService
    .execute(!this.EnseigneUserID.value ? 'createEnseigneUser': 'updateEnseigneUser', this.createUpdateFormGroup.value)
    .subscribe(res => {
      //console.log('create abonne response', res);
      if (!res.HasError) {
        this.notification.smallBox({
          title: "Succès !",
          content: (!this.EnseigneUserID.value ? "Création ": "Modification ")+"effectuée avec succès",
          color: "#35a646",
          timeout: 6000,
          icon: "fa fa-check"
      });
      this.onClose.next(true);
      this.modalRef.hide()
      } else {
        this.notification.smallBox({
        title: "Oops !",
        content: "Echec",
        color: "rgb(159,0,6)",
        timeout: 6000,
        icon: "fa fa-close"
    });
  }
    });
  }
}

}

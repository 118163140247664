import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import swal from 'sweetalert2';
import { HttpService } from 'app/core/http/http.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
@Component({
  selector: 'app-create-update-agence',
  templateUrl: './create-update-agence.component.html',
  styleUrls: ['./create-update-agence.component.css']
})
export class CreateUpdateAgenceComponent implements OnInit {

  // agenceName:string="";
  // listeAgence:any[]=[{
  //   nom:"YAO",
  //   prenom:"Kouassi",
  //   login:"yakou",
  //   contact:"09212109",
  //   statut:'COMMERCANT',
  //   createdAt:"10/10/2010"
  // }];
  // p:number=1
  @Input() agenceUserToUpdate;
  submitted = false;
  spinnerLoading:boolean=false;
  createAgenceForm:FormGroup;
  onClose: Subject<boolean> = new Subject<boolean>();
  //emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  constructor(public httpService:HttpService,private notification:NotificationService,public modalRef:BsModalRef,private formBuilder:FormBuilder) { 

  }

  ngOnInit() {
    this.onClose.next(false);
    this.createAgenceForm=this.formBuilder.group({
      Nom: [(this.agenceUserToUpdate && this.agenceUserToUpdate.Nom ) ? this.agenceUserToUpdate.Nom : '', Validators.required],
      Description: [(this.agenceUserToUpdate &&  this.agenceUserToUpdate.Description )? this.agenceUserToUpdate.Description : '', Validators.required],
      Latitude : (this.agenceUserToUpdate && this.agenceUserToUpdate["Latitude"]) ? this.agenceUserToUpdate["Latitude"] : "",
      Longitude : (this.agenceUserToUpdate && this.agenceUserToUpdate["Longitude"]) ? this.agenceUserToUpdate["Longitude"] : "",
      EnseigneAgenceID: (this.agenceUserToUpdate && this.agenceUserToUpdate["ID"]) ? this.agenceUserToUpdate["ID"] : ""
    })
    
//     if(this.detailAgence && this.detailAgence.id){
//       this.nom=this.detailAgence.nom
//       this.prenom=this.detailAgence.prenom
//       this.contact=this.detailAgence.contact
//       this.createAgenceForm.setValue({
//         email:(this.agenceEmail=this.detailAgence.email)
//       })
// this.typeUserCode=this.detailAgence.dataTypeUser.code
}
get Nom() {
  return this.createAgenceForm.get('Nom');
}
get Description() {
  return this.createAgenceForm.get('Description');
}
createOrUpdateUser(){
  this.submitted = true;
  if(this.createAgenceForm.valid){
    this.httpService
    .execute(!this.agenceUserToUpdate.ID ? 'createEnseigneAgence': 'updateEnseigneAgence', this.createAgenceForm.value)
    .subscribe(res => {
      //console.log('create abonne response', res);
      if (!res.HasError) {
        this.notification.smallBox({
          title: "Succès !",
          content: (!this.agenceUserToUpdate.ID ? "Création ": "Modification ")+"effectuée avec succès",
          color: "#35a646",
          timeout: 6000,
          icon: "fa fa-check"
      });
      this.onClose.next(true);
      this.modalRef.hide()
      } else {
        this.notification.smallBox({
        title: "Oops !",
        content: "Echec",
        color: "rgb(159,0,6)",
        timeout: 6000,
        icon: "fa fa-close"
    });
  }
    });
  }
}
    
  }

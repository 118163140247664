import {Component, OnInit, ViewContainerRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { NotificationService } from './shared/utils/notification-partenaire.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet><ngx-loading-bar [color]="color" [height]="height" [includeSpinner]="false"></ngx-loading-bar>'
})
export class AppComponent implements OnInit{
  public title = 'app works!';
  public color= "#f05713"
  height = "4px"
  public constructor(private viewContainerRef: ViewContainerRef, private router: Router, private activatedRoute: ActivatedRoute,private notificationService:NotificationService) {}
  ngOnInit(){
    // this.activatedRoute.queryParams.subscribe(params => {
    //   const appReqUuid = params['appReqUuid'];
    //   if(params['appReqUuid']){
    //     const bytes  = CryptoJS.AES.decrypt(appReqUuid, 'secret key 123');
    //     const enseigneSession = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    //     if(enseigneSession['verifyOTP']){
    //       this.router.navigate(['/dashboard']);
    //       localStorage.setItem("enseigne",JSON.stringify(enseigneSession));
    //       this.notificationService.success('Succès !', 'Bienvenue');
    //     }
    //   }
    // });
  }

}

/**
 * Created by griga on 7/11/16.
 */


import {Routes, RouterModule} from '@angular/router';
import {MainLayoutComponent} from "./shared/layout/app-layouts/main-layout.component";
import {AuthLayoutComponent} from "./shared/layout/app-layouts/auth-layout.component";
import {ModuleWithProviders} from "@angular/core";

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: '', redirectTo: 'dashboard', pathMatch: 'full'
            },
            // {
            //     path: 'home',
            //     loadChildren: 'app/+home/home.module#HomeModule'
            // },
            {
                path: 'dashboard',
                loadChildren: 'app/+accueil/accueil.module#AccueilModule'
            },
            {
                path: 'agence',
                loadChildren: 'app/+agence/agence.module#AgenceModule'
            },
            {
                path: 'admin',
                loadChildren: 'app/+admin/admin.module#AdminModule'
            },
            {
                path: 'infos',
                loadChildren: 'app/+infos/infos.module#InfosModule'
            },
            {
                path: 'contactez-nous',
                loadChildren: 'app/+contactez-nous/contactez-nous.module#ContactezNousModule'
            }
            // {
            //     path: 'users',
            //     loadChildren: 'app/+user/user.module#UserModule'
            // },
            // {
            //     path: 'type-user',
            //     loadChildren: 'app/+type-user/type-user.module#TypeUserModule'
            // },
            // {
            //     path: 'type-transaction',
            //     loadChildren: 'app/+type-transaction/type-transaction.module#TypeTransactionModule'
            // },
            // {
            //     path: 'statut',
            //     loadChildren: 'app/+statut/statut.module#StatutModule'
            // },
            // {
            //     path: 'admin',
            //     loadChildren: 'app/+admin/admin.module#AdminModule'
            // },
            // {
            //     path: 'zone',
            //     loadChildren: 'app/+zone/zone.module#ZoneModule'
            // }
        ]
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        loadChildren: 'app/+auth/auth.module#AuthModule'
    },
    
      { path: '**', redirectTo: 'accueil' }

];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {useHash: true});

import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import swal from 'sweetalert2';
import { HttpService } from 'app/core/http/http.service';
import { NotificationService } from 'app/shared/utils/notification-partenaire.service';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
// import { ImageCroppedEvent } from 'ngx-image-cropper';
import {base64ToFile} from '../../shared/image-cropper/blob.utils';
import { Dimensions,ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper'
@Component({
  selector: 'app-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.css']
})
export class AccountActivationComponent implements OnInit {

  // userName:string="";
  // listeUser:any[]=[{
  //   nom:"YAO",
  //   prenom:"Kouassi",
  //   login:"yakou",
  //   contact:"09212109",
  //   statut:'COMMERCANT',
  //   createdAt:"10/10/2010"
  // }];
  // p:number=1
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  @Input() roleUserToUpdate;
  onClose: Subject<boolean> = new Subject<boolean>();
  submitted = false;
  emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  createUpdateFormGroup: FormGroup;
  stepOneForm: FormGroup;
  stepTwoForm: FormGroup;
  stepIndex:number = 1;
  //base64Img: any;
  constructor(public httpService: HttpService, private notification: NotificationService, public modalRef: BsModalRef, private formBuilder: FormBuilder, private cd: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.onClose.next(false);
    // this.createUpdateFormGroup = this.formBuilder.group({
    //   EnseigneUserID: this.roleUserToUpdate ? this.roleUserToUpdate["ID"] : '',
    //   Login: new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Login : '', Validators.required),
    //   Nom: new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Nom : '', Validators.required),
    //   Prenoms:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Prenoms : '', Validators.required),
    //   Description:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Description : '', Validators.required),
    //   Password: this.roleUserToUpdate ? (this.roleUserToUpdate["ID"] ? '' : new FormControl('', Validators.required)) : new FormControl('', Validators.required),
    //   Contact:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Contact : '', Validators.required),
    //   Email:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Email : '', [Validators.required,Validators.pattern(this.emailPattern)]),
    //   Adresse:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.Adresse : '', Validators.required),
    //   ConfirmPassword: this.roleUserToUpdate ? (this.roleUserToUpdate["ID"] ? '' : new FormControl('', Validators.required)) : new FormControl('', Validators.required),
    //   //EnseigneID:new FormControl(this.roleUserToUpdate ? this.roleUserToUpdate.EnseigneID : '', Validators.required)
    // },{ validator: this.checkPasswords });
    this.stepOneForm = this.formBuilder.group({
      LogoEnseigne: new FormControl("", Validators.required),
      Description: new FormControl("", Validators.required),
      Adresse: new FormControl("", Validators.required)
    });

    this.stepTwoForm = this.formBuilder.group({
      codeBanqueRIB: new FormControl("", [Validators.required,Validators.minLength(5),Validators.maxLength(5)]),
      codeGuichetRIB: new FormControl("", [Validators.required,Validators.minLength(5),Validators.maxLength(5)]),
      numCompteRIB: new FormControl("", [Validators.required,Validators.minLength(11),Validators.maxLength(11)]),
      cleRIB: new FormControl("", [Validators.required,Validators.minLength(2),Validators.maxLength(2)]),
      codePaysIBAN: new FormControl("", [Validators.required,Validators.minLength(2),Validators.maxLength(2)]),
      cleControleIBAN: new FormControl("", [Validators.required,Validators.minLength(2),Validators.maxLength(2)]),
      codeBanqueIBAN: new FormControl("", [Validators.required,Validators.minLength(5),Validators.maxLength(5)]),
      codeGuichetIBAN: new FormControl("", [Validators.required,Validators.minLength(5),Validators.maxLength(5)]),
      numCompteIBAN: new FormControl("", [Validators.required,Validators.minLength(11),Validators.maxLength(11)]),
      cleIBAN: new FormControl("", [Validators.required,Validators.minLength(2),Validators.maxLength(2)]),
      codeBanqueBIC: new FormControl("", [Validators.required,Validators.minLength(4),Validators.maxLength(4)]),
      codeISOPaysBIC: new FormControl("", [Validators.required,Validators.minLength(2),Validators.maxLength(2)]),
      localisationBIC: new FormControl("", [Validators.required,Validators.minLength(2),Validators.maxLength(2)]),
      codeBrancheBIC: new FormControl("", [Validators.minLength(3),Validators.maxLength(3)])
    })
  }
  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.Password.value;
    let confirmPass = group.controls.ConfirmPassword.value;
    return pass === confirmPass ? null : { notSame: true }
  }
  onFileChange(event) {
    this.imageChangedEvent = event;
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        let data = (reader.result as string);
        //this.base64Img = data;
        this.stepOneForm.patchValue({
          LogoEnseigne: data.split(",")[1]
       });
      
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
        //console.log("dat");
        //console.log("form value", this.stepOneForm.value);
        
      };
    }
  }
  fileChangeEvent(fileInput: any) {
    
    if (fileInput.target.files && fileInput.target.files[0]) {
      //this.required.file=false;
      // this.fileName=fileInput.target.files[0].name;
      // this.typeFichier=fileInput.target.files[0].type;
      // let splitFileNameLength:number=fileInput.target.files[0].name.split(".").length;
      // this.fileExtension=fileInput.target.files[0].name.split(".")[splitFileNameLength-1];
      
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        console.log("ici");
        let data = (readerEvent.target as any).result;
        this.stepOneForm.patchValue({
          LogoEnseigne: data.split(",")[1]
        })
        reader.readAsDataURL(fileInput.target.files[0]);
        console.log("base64file", this.stepOneForm.value)
        //setTimeout(() => console.log("base64file", this.stepOneForm.value), 100);
      }
    }
  }

  get LogoEnseigne () {
    return this.stepOneForm.get('LogoEnseigne');
  }
  get Description () {
    return this.stepOneForm.get('Description');
  }
  get Adresse () {
    return this.stepOneForm.get('Adresse');
  }
  // imageChangeEvent(event: any): void {
  //     this.imageChangedEvent = event;
  // }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
        this.stepOneForm.patchValue({
          LogoEnseigne: this.croppedImage.split(",")[1]
       });
      //console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
      this.showCropper = true;
      console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
      console.log('Load failed');
  }
  activateAccount(){

    const data = {
      Rib: this.stepTwoForm.value.codeBanqueRIB + this.stepTwoForm.value.codeGuichetRIB + this.stepTwoForm.value.numCompteRIB +  this.stepTwoForm.value.cleRIB ,
      Iban: this.stepTwoForm.value.codePaysIBAN + this.stepTwoForm.value.cleControleIBAN +this.stepTwoForm.value.codeBanqueIBAN + this.stepTwoForm.value.codeGuichetIBAN + this.stepTwoForm.value.numCompteIBAN + this.stepTwoForm.value.cleIBAN,
      Bic: this.stepTwoForm.value.codeBanqueBIC + this.stepTwoForm.value.codeISOPaysBIC + this.stepTwoForm.value.localisationBIC + this.stepTwoForm.value.codeBrancheBIC,
      ...this.stepOneForm.value
    };
    this.httpService.execute('updateEnseigneActivationInfos', data).subscribe(res => {
      //console.log('check otp response', res);
      if (!res.HasError) {
        let userData = res.Data;
        localStorage.setItem('enseigne', JSON.stringify(userData));
        this.notification.success('Succès !', 'Votre compte a été activé avec succès');
        this.modalRef.hide();
      } else this.notification.error('Oops !', res.Message);
    },
      err =>{
        // console.log("err",err);
        
        // this.modalRef.hide();
      });
  }
  // get Login() {
  //   return this.createUpdateFormGroup.get('Login');
  // }
  // get Nom() {
  //   return this.createUpdateFormGroup.get('Nom');
  // }
  // get Prenoms() {
  //   return this.createUpdateFormGroup.get('Prenoms');
  // }
  // get Description() {
  //   return this.createUpdateFormGroup.get('Description');
  // }
  // get Password() {
  //   return this.createUpdateFormGroup.get('Password');
  // }
  // get Contact() {
  //   return this.createUpdateFormGroup.get('Contact');
  // }
  // get Email() {
  //   return this.createUpdateFormGroup.get('Email');
  // }
  // get Adresse() {
  //   return this.createUpdateFormGroup.get('Adresse');
  // }
  // get ConfirmPassword() {
  //   return this.createUpdateFormGroup.get('ConfirmPassword');
  // }
  // get EnseigneID() {
  //   return this.createUpdateFormGroup.get('EnseigneID');
  // }
  // createOrUpdateUser(){
  //   this.submitted = true;
  //   delete this.createUpdateFormGroup.value.ConfirmPassword;
  //   if(this.roleUserToUpdate && this.roleUserToUpdate["ID"]) delete this.createUpdateFormGroup.value.Password;
  //   else delete this.createUpdateFormGroup.value['EnseigneUserID'];
  //   if(this.createUpdateFormGroup.valid){
  //     this.httpService
  //     .execute(!this.EnseigneUserID.value ? 'createEnseigneUser': 'updateEnseigneUser', this.createUpdateFormGroup.value)
  //     .subscribe(res => {
  //       //console.log('create abonne response', res);
  //       if (!res.HasError) {
  //         this.notification.smallBox({
  //           title: "Succès !",
  //           content: (!this.EnseigneUserID.value ? "Création ": "Modification ")+"effectuée avec succès",
  //           color: "#35a646",
  //           timeout: 6000,
  //           icon: "fa fa-check"
  //       });
  //       this.onClose.next(true);
  //       this.modalRef.hide()
  //       } else {
  //         this.notification.smallBox({
  //         title: "Oops !",
  //         content: "Echec",
  //         color: "rgb(159,0,6)",
  //         timeout: 6000,
  //         icon: "fa fa-close"
  //     });
  //   }
  //     });
  //   }
  // }

}

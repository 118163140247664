import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import swal from 'sweetalert2';
import { HttpService } from 'app/core/http/http.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit,AfterViewInit {

  // adminName:string="";
  // listeAdmin:any[]=[{
  //   nom:"YAO",
  //   prenom:"Kouassi",
  //   login:"yakou",
  //   contact:"09212109",
  //   statut:'COMMERCANT',
  //   createdAt:"10/10/2010"
  // }];
  // p:number=1
  @Input() detailAdmin;
  spinnerLoading:boolean=false;
  nom:string="";
  prenom:string="";
  contact:string="";
  password:string="";
  confirmPassword:string="";
  typeUserCode:any="";
  listeTypeUser:any[]=[];
  required:any={
    nom:false,
    prenom:false,
    contact:false,
    password:false,
    confirmPassword:false,
    typeUser:false,
    email:false
  }
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  createAdminForm=this.formBuilder.group({
    email: ['', Validators.pattern(this.emailPattern)]
  })
  adminEmail:string="";
  constructor(public httpService:HttpService,private notification:NotificationService,public modalRef:BsModalRef,private formBuilder:FormBuilder) { 

  }

  ngOnInit() {
    
    if(this.detailAdmin && this.detailAdmin.id){
      this.nom=this.detailAdmin.nom
      this.prenom=this.detailAdmin.prenom
      this.contact=this.detailAdmin.contact
      this.createAdminForm.setValue({
        email:(this.adminEmail=this.detailAdmin.email)
      })
this.typeUserCode=this.detailAdmin.dataTypeUser.code
}
    //console.log(this.typeUserCode);
    
  }
  ngAfterViewInit(){
    this.getTypeUser()
  }

  getTypeUser(){
    let data={
      user:1,
      data:{
        isAdmin:true
      }
    }
    this.httpService.execute('typeUser/getByCriteriaa',data).subscribe(res=>{
      if(res.items && res.items.length) this.listeTypeUser=res.items
      else this.listeTypeUser=[]

      //   this.notification.smallBox({
      //     title: "Succès !",
      //     content: "Votre admin a été créé avec succès",
      //     color: "#35a646",
      //     timeout: 6000,
      //     icon: "fa fa-check"
      // });

    },
err=>{
    /*this.notification.smallBox({
      title: "Oops !",
      content: 'Problème de connexion',
      color: "rgb(159, 0, 6)",
      timeout: 6000,
      icon: "fa fa-close"
  });*/
  })
  }
// voir(){
//   this.createAdminForm.setValue({
//     email:this.adminEmail
//   })
//   console.log("erreur email",this.createAdminForm.status)
// }
  createOrUpdateAdmin(){
    this.createAdminForm.setValue({
      email:this.adminEmail
    })
    if(this.nom && this.prenom && (this.contact && this.contact.length===8) /*&& this.password && (this.password==this.confirmPassword)*/ && this.typeUserCode && this.adminEmail && (this.createAdminForm.status=="VALID")){
      this.spinnerLoading=true
    let data={
      "user":"1",
      "datas":[
        {
          id:(this.detailAdmin && this.detailAdmin.id) ? this.detailAdmin.id:"",
          "nom": this.nom,
          "prenom": this.prenom,
          "email":this.adminEmail,
          //"code": "",
          //"password": (this.detailAdmin && this.detailAdmin.id) ? "" :this.password,
          "contact": this.contact,
          //"typeUserId": this.typeUserCode.id,
          "typeUserCode":this.typeUserCode
        } 
      ]  
    }
    this.httpService.execute((this.detailAdmin && this.detailAdmin.id) ? 'user/update' :'user/create',data,).subscribe(res=>{
      if(!res.hasError) {this.notification.smallBox({
          title: "Succès !",
          content: (this.detailAdmin && this.detailAdmin.id) ? "L'utilisateur a été modifié avec succès":"L'utilisateur a été créé avec succès",
          color: "#35a646",
          timeout: 6000,
          icon: "fa fa-check"
      });
      this.modalRef.hide()
    }

      else    this.notification.smallBox({
        title: "Oops !",
        content: res.status.message,
        color: "rgb(159, 0, 6)",
        timeout: 6000,
        icon: "fa fa-close"
    });
    this.spinnerLoading=false
    },
  err=>{
    /*this.notification.smallBox({
      title: "Oops !",
      content: 'Problème de connexion',
      color: "rgb(159, 0, 6)",
      timeout: 6000,
      icon: "fa fa-close"
  });*/
  this.spinnerLoading=false
  })
  }
  else{
    if(!this.nom) this.required.nom=true
    if(!this.prenom) this.required.prenom=true
    if(!this.contact || (this.contact.length!==8)) this.required.contact=true
    // if(!this.password) this.required.password=true
    // if(this.confirmPassword!==this.password) this.required.confirmPassword=true
    if(!this.typeUserCode) this.required.typeUser=true
    if(!this.adminEmail) this.required.email=true
  }
}

}

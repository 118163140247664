import { Component, OnInit, Input } from '@angular/core';
import swal from 'sweetalert2';
import { HttpService } from 'app/core/http/http.service';
import { NotificationService } from 'app/shared/utils/notification.service';
import { BsModalRef } from 'ngx-bootstrap';
@Component({
  selector: 'app-create-type-user',
  templateUrl: './create-type-user.component.html',
  styleUrls: ['./create-type-user.component.css']
})
export class CreateTypeUserComponent implements OnInit {

  // userName:string="";
  // listeTypeUser:any[]=[{
  //   nom:"YAO",
  //   prenom:"Kouassi",
  //   login:"yakou",
  //   contact:"09212109",
  //   statut:'COMMERCANT',
  //   createdAt:"10/10/2010"
  // }];
  // p:number=1
  @Input() detailTypeUser;
  spinnerLoading:boolean=false;
  libelle:string="";
  code:string="";
  required:any={
    libelle:false
  }
  constructor(public httpService:HttpService,private notification:NotificationService,public modalRef:BsModalRef) { 

  }

  ngOnInit() {
    if(this.detailTypeUser && this.detailTypeUser.id){
      this.libelle=this.detailTypeUser.libelle
      this.code=this.detailTypeUser.code
    }
    // console.log(this.detailTypeUser);
    
    //this.getTypeUser()
  }

  // getTypeUser(){
  //   let data={}
  //   this.httpService.execute('typeUser/getByCriteria',data).subscribe(res=>{
  //     if(res.items && res.items.length) this.listeTypeUser=res.items
  //     else this.listeTypeUser=[]
  //   },
  // err=>{
  //   this.notification.smallBox({
  //     title: "Oops !",
  //     content: 'Problème de connexion',
  //     color: "rgb(159, 0, 6)",
  //     timeout: 6000,
  //     icon: "fa fa-close"
  // });
  // })
  // }

  createOrUpdateTypeUser(){
    if(this.libelle){
    this.spinnerLoading=true
    let data={
      "user":"1",
      "datas":[
        {
          id:(this.detailTypeUser && this.detailTypeUser.id) ? this.detailTypeUser.id:"",
          "libelle": this.libelle,
          "code": this.libelle.toUpperCase(),
        } 
      ]  
    }
    this.httpService.execute((this.detailTypeUser && this.detailTypeUser.id) ? 'user/update' :'user/create',data).subscribe(res=>{
      if(!res.hasError) {
        this.notification.smallBox({
          title: "Succès !",
          content: (this.detailTypeUser && this.detailTypeUser.id) ? "Le type d'utilisateur a été modifié avec succès":"Le type d'utilisateur a été créé avec succès",
          color: "#35a646",
          timeout: 6000,
          icon: "fa fa-check"
      });
      this.modalRef.hide()
    }

      else  this.notification.smallBox({
        title: "Oops !",
        content: res.status.message,
        color: "rgb(159, 0, 6)",
        timeout: 6000,
        icon: "fa fa-close"
    });
    this.spinnerLoading=false
    },
  err=>{
    /*this.notification.smallBox({
      title: "Oops !",
      content: 'Problème de connexion',
      color: "rgb(159, 0, 6)",
      timeout: 6000,
      icon: "fa fa-close"
  });*/
  this.spinnerLoading=false
  })
  }
  else{
    if(!this.libelle) this.required.libelle=true
  }
}
changeToUpperCase(){
  if(this.libelle) this.libelle=this.libelle.toUpperCase()
}

}

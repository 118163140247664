import { Injectable } from "@angular/core";

import { ToastrService } from "ngx-toastr";

@Injectable()
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  success(title,message) {
    this.toastr.success(message, title);
  }

  error(title,message) {
    this.toastr.error(message, title);
  }

  info(title,message) {
    this.toastr.info(message, title);
  }

  warning(title,message) {
    this.toastr.warning(message, title);
  }
}

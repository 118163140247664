import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { _throw } from 'rxjs/observable/throw';
import { Router } from '@angular/router';
@Injectable()
export class HttpService {
  phoneMask: string = "00 00 00 00 00";

  constructor(private http: HttpClient, private router: Router) { }
  execute(method: string, data?: Object, options?: Object): Observable<any> {
    return (data || data === '')
      ? this.http
        .post(environment.BASE_URL + method, data, options)
        .pipe(catchError(this.handleError))
      : this.http
        .get(environment.BASE_URL + method, options)
        .pipe(catchError(this.handleError));
  }
  searchAgence(params): Observable<any> {
    return this.http.get(environment.SEARCH_BASE_URL+'/search/', {
      params: params, headers: new HttpHeaders(
        {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*'
        }
      )
    }).catch(this.handleError)
  }

  // public get phoneMaskValue() : string {
  //   return this.phoneMask;
  // }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      if (error.status === 403) {
        console.log('403 error');
        localStorage.setItem('expired_token', JSON.stringify(true));
        window.open(location.origin + '/#/auth/', '_self');
      }
    }
    // return an observable with a user-facing error message
    return _throw('Something bad happened; please try again later.'); /*return throwError('Something bad happened; please try again later.')*/;
  }
}

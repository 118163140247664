import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
} from '@angular/common/http';

import { Observable } from 'rxjs';


@Injectable()
export class RequestInterceptor implements HttpInterceptor {
constructor(){}
  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle((httpRequest.url.includes('create') || httpRequest.url.includes('authenticate') || httpRequest.url.includes('getAllPays')) ? 
    ( !(httpRequest.url.includes('createEnseigneUser') || httpRequest.url.includes('createEnseigneAgence')) ? httpRequest : httpRequest.clone({
      headers: new HttpHeaders({
        Token: JSON.parse(localStorage.getItem('enseigne')).Token
      })
    })
    )
    : 
    (
      httpRequest.url.includes('nominatim') ? httpRequest :
    httpRequest.clone({
        headers: new HttpHeaders({
          Token: JSON.parse(localStorage.getItem('enseigne')).Token
        })
      })
    )
      )
  }
}

import { Component, OnInit } from '@angular/core';
import {LayoutService} from "../layout.service";
import { Router } from '@angular/router';

@Component({
  selector: 'sa-ribbon',
  templateUrl: './ribbon.component.html',
  styleUrls: ['./ribbon.component.css']
})
export class RibbonComponent implements OnInit {
  enseigneName:string;
  search:string;
  constructor(private layoutService: LayoutService,private router:Router) {}

  ngOnInit() {
    this.enseigneName = !!JSON.parse(localStorage.getItem('enseigne')) ? JSON.parse(localStorage.getItem('enseigne')).Nom: "Admin";
  }

  resetWidgets() {
    this.layoutService.factoryReset()
  }
  logout(){
    localStorage.removeItem('enseigne');
    this.router.navigate(['/auth'])
  }
}
